import { useEffect } from 'react';

type Props = {
  activeStep: number;
};

export function ScrollToTop({ activeStep }: Props): null {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return null;
}
